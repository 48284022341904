<template>
  <div class="d-flex align-items-center justify-content-center">
    <div
      class="d-flex"
      :class="[$style[`prev-page`], {[$style[`disabled`]]: currentPage === 1}]"
      @click="setPrevPage"
    >
      <Icon
        name="prev"
        :class="$style[`icon`]"
      />
    </div>
    <div
      v-for="page in pageList"
      :key="page"
      :class="[$style[`page`], {[$style[`current-page`]]: page+1 === currentPage}]"
      @click="setPage(page+1)"
    >
      {{ page+1 }}
    </div>
    <div
      class="d-flex"
      :class="[$style[`next-page`], {[$style[`disabled`]]: currentPage === totalPage}]"
      @click="setNextPage"
    >
      <Icon
        name="next"
        :class="$style[`icon`]"
      />
    </div>
  </div>
</template>
<script>
import { toRef, computed } from 'vue'
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalPage: {
      type: Number,
      default: 1
    }
  },
  emits: ['setPage'],
  setup (props, { emit }) {
    const currentPage = toRef(props, 'currentPage')
    const totalPage = toRef(props, 'totalPage')

    const pageList = computed(() => {
      const totalPageList = [...Array(totalPage.value).keys()]
      const start = currentPage.value - 2 >= 0 ? currentPage.value - 2 : 0
      const end = currentPage.value + 2
      return totalPageList.slice(start, end)
    })

    const setPage = (page) => {
      emit('setPage', page)
    }

    const setPrevPage = () => {
      if (currentPage.value !== 1) {
        setPage(currentPage.value - 1)
      }
    }

    const setNextPage = () => {
      if (currentPage.value !== totalPage.value) {
        setPage(currentPage.value + 1)
      }
    }

    return { pageList, setPrevPage, setNextPage, setPage }
  }
}
</script>
<style lang="scss" module>
.page {
  align-items: center;
  color: $dark;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 1.2rem;
  justify-content: center;
  margin: 0 0.5rem;
  user-select: none;
  width: 1.2rem;
}

.page.current-page {
  background-color: $primary;
  color: $white;
  cursor: unset;
}

.prev-page,
.next-page {
  cursor: pointer;
  margin: 0 0.8rem;
}

.prev-page.disabled,
.next-page.disabled {
  cursor: unset;
  opacity: 0.5;
}

.icon {
  fill: $primary;
  height: 0.9rem;
  width: 0.9rem;
}
</style>
