<template>
  <aside
    id="product-side-nav"
    class="d-none d-md-block col-3"
  >
    <div
      :class="{'rendered': categoryList}"
    >
      <div
        :class="$style[`dropdown`]"
      >
        <div
          class="font-weight-bold text-decoration-none px-1"
          :class="$style[`dropdown-item-title`]"
          role="button"
          @click="searchSpecificCategory"
        >
          所有商品
        </div>
      </div>
      <div
        v-for="(category, index) in categoryList"
        :key="index"
        class="position-relative"
      >
        <div
          :class="[$style[`dropdown`],
                   {[$style[`active`]]: showedCategoryId === category.catMainID},
                   $style[`dropdown-arrow`]]"
          class="font-weight-bold"
          @click="category.subCategoryList.length>1 ? setShowedCategoryId(category.catMainID) : null"
        >
          <div
            class="d-flex justify-content-between align-items-center font-weight-bold ps-1 pe-3"
            :class="$style[`dropdown-item-title`]"
            role="button"
            @click="category.subCategoryList.length>1 ? null : push('/product/list?catMainID='+category.catMainID)"
          >
            {{ category.catMain }}
            <img
              v-if="category.subCategoryList.length>1"
              data-src="@/assets/img/next.svg"
              class="svg category-navbar-svg"
            >
          </div>

          <div
            v-if="category.subCategoryList.length>1"
            :class="$style[`dropdown-menu`]"
          >
            <a
              v-for="sub in category.subCategoryList"
              :key="sub.catSubID"
              role="button"
              :class="[$style[`dropdown-item`], {'d-none': sub.catSubID==='none'}]"
              @click="searchSpecificCategory(sub.catMainID, sub.catSubID)"
            >{{ sub.catSub }}</a>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <!-- 篩選 -->
    <div class="font-weight-bold d-flex justify-content-between align-items-center mb-3">
      篩選
      <Icon
        name="search"
        :class="$style[`icon`]"
      />
    </div>
    <div class="d-flex felx-column mx-2">
      <div class="row justify-content-between text-center">
        <!-- 關鍵字 -->
        <input
          ref="queryString"
          v-model="searchParams.queryString"
          type="text"
          class="col-12 form-control bg-transparent mb-3"
          placeholder="關鍵字"
          @keydown.enter="readProductList"
        >

        <!-- 分類 -->
        <select
          ref="catMainID"
          v-model="searchParams.catMainID"
          name="catMainID"
          required
          class="col-12 form-select check-input mb-3"
        >
          <option
            :value="null"
            selected
            disabled
          >
            大分類
          </option>
          <option
            v-for="(c,index) in categoryList"
            :key="index"
            :value="c.catMainID"
          >
            {{ c.catMain }}
          </option>
        </select>
        <select
          ref="catSubID"
          v-model="searchParams.catSubID"
          :disabled="!(searchParams?.catMainID) || !selectedCategory?.subCategoryList || selectedCategory.subCategoryList.length<=1"
          name="catSubID"
          required
          class="col-12 form-select rounded-right check-input mb-3"
        >
          <option
            :value="null"
            selected
            disabled
          >
            子分類
          </option>
          <option
            v-for="(c,index) in selectedCategory.subCategoryList"
            :key="index"
            :value="c.catSubID"
            :class="{'d-none': c.catSubID==='none'}"
          >
            {{ c.catSub }}
          </option>
        </select>
        <!-- 商品排序 -->
        <!--                                 <select name="catSubID" required class="col-12 form-control rounded-right check-input bg-transparent pointer mb-3"> -->
        <!--                                     <option value="null" disabled selected>商品排序</option> -->
        <!--                                     <option v-for="c in subCateList" :value="c.catSubID">{{c.catSub}}</option> -->
        <!--                                 </select> -->
        <div class="col-12 form-inline d-flex flex-row justify-content-between p-0">
          <button
            class="btn btn-outline-primary rounded-0 letter-spacing-s"
            @click="resetSearchParams"
          >
            重設
          </button>
          <button
            class="btn btn-primary text-white rounded-0 letter-spacing-s"
            @click="readProductList"
          >
            搜尋
          </button>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
import { inject, ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const { push } = useRouter()

    const searchParams = inject('searchParams')
    const categoryList = inject('categoryList')
    const selectedCategory = inject('selectedCategory')
    const readProductList = inject('readProductList')
    const resetSearchParams = inject('resetSearchParams')
    const searchSpecificCategory = inject('searchSpecificCategory')

    const showedCategoryId = ref('')
    const setShowedCategoryId = (id) => {
      if (showedCategoryId.value === id) {
        showedCategoryId.value = ''
      } else {
        showedCategoryId.value = id
      }
    }

    return {
      push,
      searchParams,
      categoryList,
      selectedCategory,
      readProductList,
      resetSearchParams,
      showedCategoryId,
      setShowedCategoryId,
      searchSpecificCategory
    }
  }
}
</script>
<style lang="scss" module>

.icon {
  height: 0.9rem;
  width: 0.9rem;
}

.dropdown {
  &.dropdown-arrow::before {
    border-bottom: 0.13rem solid $dark;
    border-left: 0.13rem solid $dark;
    border-right: 0 solid transparent;
    border-top: 0 solid transparent;
    content: '';
    display: block;
    height: 0.35rem;
    position: absolute;
    right: 0;
    transform: rotate(-45deg) translate(-26px, -5px);
    transition: border 0.3s;
    width: 0.35rem;
  }

  &.dropdown-arrow.active::before {
    border-bottom: 0 solid transparent;
    border-left: 0 solid transparent;
    border-right: 0.13rem solid $dark;
    border-top: 0.13rem solid $dark;
  }

  .dropdown-menu {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    transition: max-height 0.3s;
  }

  &.active .dropdown-menu {
    max-height: 100vh;
  }

  .dropdown-item {
    color: $dark;
    padding: 0.5rem 1rem;
    text-decoration: none;
  }

  .dropdown-item:hover {
    background-color: $primary;
    color: $white;
  }

  .dropdown-item-title {
    color: $dark;
    padding: 0.5rem 1rem;
    text-align: left;
    text-decoration: none;
  }
}

</style>
