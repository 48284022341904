<template>
  <div>
    <Slider
      :allow-touch-move="false"
      :slides-per-view="1"
      class="mb-5"
    >
      <template #default>
        <SwiperSlide>
          <img
            class="d-none d-md-block w-100 h-100"
            :class="[$style[`object-fit-cover`], $style[`slider-height`]]"
            src="@/assets/img/index/main_banner.webp"
          >
          <img
            class="d-block d-md-none w-100 h-100"
            :class="[$style[`object-fit-cover`], $style[`slider-height`]]"
            src="@/assets/img/index/main_banner_mobile.webp"
          >
        </SwiperSlide>
      </template>
    </Slider>

    <div class="container-lg py-5">
      <div class="row py-5">
        <!-- navbar -->
        <suspense>
          <template #default>
            <Aside />
          </template>
          <template #fallback>
            <div />
          </template>
        </suspense>

        <section class="col-12 col-md-9">
          <!-- title -->
          <h4
            class="title-border-bottom d-flex justify-content-between"
          >
            <div>
              <span v-if="searchResultParams?.catMain && searchResultParams?.catMain !== ''">
                {{ searchResultParams.catMain }}
                <span v-show="searchResultParams?.catSub !== ''">/ {{ searchResultParams.catSub }}</span>
              </span>
              <span v-else>全部商品</span>
              <span
                v-if="searchResultParams?.queryString"
                :class="[$style[`transition-opacity`], {'opacity-0': isLoading}]"
              > / {{ searchResultParams.queryString }} 的搜尋結果</span>
            </div>
            <div
              class="d-flex flex-row align-items-center"
              :class="[$style[`transition-opacity`], {'opacity-0': isLoading}]"
            >
              <span
                v-if="page.totalElement"
                class="small pr-2"
              >{{ page.totalElement }}件商品</span>
              <!-- page nav -->
              <PagePanigation
                v-show="!isLoading"
                class="d-none d-md-flex"
                :current-page="page?.current"
                :total-page="page?.totalPage"
                @setPage="readProductList"
              />
            <!-- <div
              ref="pagination"
              :class="{ 'd-none': !showPagePagination }"
              class="d-none d-md-flex pagination-wrap simple-pagination justify-content-center light-theme"
            /> -->
            </div>
          </h4>

          <!-- 顯示商品選項 -->
          <div class="container-fluid d-block d-md-none py-4">
            <div class="row justify-content-between text-center">
              <!-- 商品排序 -->
              <select
                name="catSubID"
                required
                class="col-12 form-select rounded-right check-input bg-transparent pointer mb-3"
              >
                <option
                  value="null"
                  disabled
                  selected
                >
                  商品排序
                </option>
              <!-- <option v-for="c in subCategoryList" :value="c.catSubID">{{c.catSub}}</option> -->
              </select>
            </div>
          </div>

          <!-- empty msg -->
          <div
            v-if="productList.length===0 && !isLoading"
            class="col-12 text-center h4 py-4"
          >
            Oops！這一系列還沒有商品喔！
          </div>

          <!-- product list -->
          <div
            class="container-fluid py-4 product-list-wrap"
            :class="{'rendered': productList.length!==0}"
          >
            <div class="row py-0">
              <div
                v-for="(product,index) in productList"
                :key="index"
                class="col-6 col-lg-3 mb-3"
              >
                <router-link
                  class="position-relative d-block text-dark"
                  :class="$style[`product-wrap`]"
                  :to="`/product/detail/${product.productID}`"
                >
                  <!-- <div class="product-tag"
                :class="$style[`product-tag`]">HOT</div>
                                    <div class="product-tag"
                :class="$style[`product-tag`]">SALE</div> -->
                  <div
                    class="mb-2"
                    :class="$style[`product-img`]"
                    :style="[product.mainImg ? {backgroundImage: `url(${product.mainImg})`} : {backgroundImage: `url('@/assets/img/nopic.gif')`}]"
                  />
                  <div
                    v-if="product.hasStorage"
                    class="position-absolute start-0 top-0 bg-primary text-white px-3 py-1"
                  >
                    現貨
                  </div>
                  <!-- <div v-if="product.hasCw"
                 class="position-absolute start-0 top-0 bg-primary text-white">現貨</div> -->
                  <div class="text-start">
                    <div class="mb-1">
                      {{ product.name }}
                    </div>
                    <div
                      v-if="product.minPrice==product.maxPrice"
                      class="text-secondary"
                    >
                      NTD ${{ product.minPrice }}
                    </div>
                    <div
                      v-else
                      class="text-secondary"
                    >
                      NTD ${{ product.minPrice }}-{{ product.maxPrice }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>

          <!-- page nav -->
          <PagePanigation
            v-show="!isLoading"
            class="d-none d-md-flex mb-2"
            :current-page="page?.current"
            :total-page="page?.totalPage"
            @setPage="readProductList"
          />
          <span
            class="small pr-2"
          >{{ page.offset * pageSize }} - {{ page.current * pageSize }} 項商品 / 共 {{ page.totalPage }} 頁</span>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, watch, provide, inject } from 'vue'
import { useStore } from 'vuex'
import Aside from '@/components/front/product/list/Aside'
import PagePanigation from '@/components/PagePanigation'
import Slider from '@/components/Slider.vue'
import { SwiperSlide } from 'swiper/vue'
import { useRouter, useRoute } from 'vue-router'
export default {
  name: 'ProductList',
  components: {
    Aside, Slider, SwiperSlide, PagePanigation
  },
  props: {
    queryString: {
      type: String,
      default: ''
    },
    categoryID: {
      type: String,
      default: ''
    },
    catMainID: {
      type: String,
      default: ''
    },
    catSubID: {
      type: String,
      default: ''
    }
  },
  async setup (props) {
    const { dispatch, getters } = useStore()
    const { push } = useRouter()
    const route = useRoute()

    const isLoading = computed(() => getters.getIsLoading)
    const categoryList = computed(() => getters['product/getCategoryList'])
    const productList = computed(() => getters['product/getProductList'])

    const setAlert = inject('setAlert')

    push({ path: '/product/list', replace: true })

    const pageSize = ref(20)
    const page = ref({
      totalElement: 0,
      current: 1,
      offset: 1,
      totalPage: 1
    })
    const initSet = ref({ // 初始查詢設定
      begin: 1,
      end: 20
    })
    const searchParams = ref({
      queryString: props?.queryString.value || null,
      isActive: null,
      offset: '',
      pageSize: '',
      catMainID: props?.catMainID.value || null,
      catMain: null,
      catSubID: props?.catSubID.value || null,
      catSub: null
    })
    const searchResultParams = ref({
      queryString: '',
      isActive: null,
      offset: '',
      pageSize: ''
    })

    provide('categoryList', categoryList)
    provide('searchParams', searchParams)
    // const showData = computed(() => {
    //   const begin = (this.page.current - 1) * this.initSet.end
    //   const end = (this.page.current) * this.initSet.end

    //   return this.productList.slice(begin, end)
    // })

    const clearSelectedSubCategory = () => {
      searchParams.value.catSubID = null
      searchParams.value.catSub = null
    }

    const selectedCategory = computed(() => {
      if (window.document.activeElement.tagName === 'SELECT') {
        clearSelectedSubCategory()
      }
      return categoryList.value.find(el => el.catMainID === searchParams.value.catMainID) || {}
    })
    provide('selectedCategory', selectedCategory)

    const setSearchResultParams = () => {
      searchResultParams.value = JSON.parse(JSON.stringify(searchParams.value))
      if (categoryList.value.length > 0) {
        const catMainItem = searchResultParams.value.catMainID
          ? categoryList.value.find(el => el.catMainID === searchResultParams.value.catMainID)
          : undefined
        searchResultParams.value.catMain = catMainItem && catMainItem?.catMain ? catMainItem?.catMain : ''

        const catSubItem = selectedCategory.value.subCategoryList && searchResultParams.value.catSubID
          ? selectedCategory.value.subCategoryList.find(el => el.catSubID === searchResultParams.value.catSubID)
          : undefined
        searchResultParams.value.catSub = catSubItem && catSubItem?.catSub ? catSubItem?.catSub : ''
      }
    }

    const readProductList = async (pageNumber) => {
      setSearchResultParams()
      page.value.totalElement = 0
      if (typeof pageNumber === 'object' || !pageNumber) {
        pageNumber = 1
      }
      const payload = { ...searchParams.value, name: searchParams.value.queryString }
      payload.offset = (pageNumber - 1) * pageSize.value
      payload.pageSize = pageSize.value
      payload.needSpec1AndSpec2AndSpecPrice = false
      initSet.value.begin = 1
      page.value.current = pageNumber || 1
      try {
        const response = await dispatch('product/readProductList', { payload })
        page.value.offset = response.data.data?.page?.offset || 0
        page.value.totalElement = response.data.data?.page?.totalElement || 0
        page.value.totalPage = response.data.data?.page?.totalPage || 1
      } catch (error) {
        console.log(error)
        page.value.totalElement = 0
      }
    }

    const searchSpecificCategory = (mainID, subID) => {
      if (mainID && subID) {
        searchParams.value.catMainID = mainID
        searchParams.value.catSubID = subID
      } else {
        // all product without category filter
        resetSearchParams()
      }
      readProductList()
    }

    const resetSearchParams = () => {
      searchParams.value = {
        queryString: '',
        isActive: null,
        offset: '',
        pageSize: '',
        catMainID: null,
        catSubID: null
      }
    }

    const getCategoryList = async () => {
      const payload = { }

      try {
        await dispatch('product/readCategoryList', { payload })
      } catch (error) {
        console.log(error)
        setAlert(true, false, error)
      }
    }

    watch(route, async () => {
      // not redirect
      if (route.path === '/product/list') {
        if (Object.keys(route.query).length !== 0) {
          const queryString = route.query?.queryString || null
          let catMainID = route.query?.catMainID || null
          let catSubID = route.query?.catSubID || null
          const CategoryID = route.query?.categoryID
          if (route.query?.categoryID && categoryList.value.length > 0) {
            categoryList.value.find((category) => {
              if (category.catMainID === CategoryID) {
                catMainID = category.catMainID
                return false
              }
              category.subCategoryList.find((subCate) => {
                if (subCate.catSubID === CategoryID) {
                  catMainID = category.catMainID
                  catSubID = subCate.catSubID
                  return false
                }
              })
            })
          }

          searchParams.value.queryString = queryString
          searchParams.value.catMainID = catMainID
          searchParams.value.catSubID = catSubID
          push({ path: '/product/list', replace: true })
        } else {
          await readProductList(1)
        }
      }
    }, { immediate: true })

    provide('readProductList', readProductList)
    provide('searchSpecificCategory', searchSpecificCategory)
    provide('resetSearchParams', resetSearchParams)

    if (categoryList.value.length === 0) {
      await getCategoryList()
    }

    return {
      isLoading,
      categoryList,
      searchParams,
      searchResultParams,
      resetSearchParams,
      readProductList,
      productList,
      selectedCategory,
      searchSpecificCategory,
      page,
      pageSize
    }
  }
}
</script>
<style lang="scss" module>

.slider-height {
  height: 400px;
}

.object-fit-cover {
  object-fit: cover;
}

.transition-opacity {
  transition: opacity 0.3s;
}

/* product */
.product-wrap {
  overflow: hidden;
  text-decoration: none;
}

.product-tag {
  background-color: rgb(var(--pantone-color));
  color: $white;
  font-weight: bold;
  left: 0.5rem;
  padding: 0.75rem 1.25rem;
  position: absolute;
  top: 0;
}

.product-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-bottom: 100%;
}

</style>
